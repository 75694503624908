@use '../abstracts/' as *;
@use '../utilities/' as *;

.current-forecast {
    width: 100%;
    height: 100%;

    display: grid;
    grid-template-columns: auto 1fr 28.65rem;
    gap: $spacer;
    margin-bottom: $spacer;

    &__left {
        grid-template-columns: 1 / 2;
    }

    &__middle {
        grid-template-columns: 2 / 3;
    }

    &__right {
        grid-template-columns: 3 / 4;
    }
}

@include media-breakpoint-down(xl) {
    .current-forecast {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;

        &__left {
            grid-column: 1 / 2;
            grid-row: 1 / 2;
        }

        &__middle {
            grid-column: 1 / 3;
            grid-row: 2 / 3;
        }

        &__right {
            grid-column: 2 / 3;
            grid-row: 1 / 2;
        }
    }
}

@include media-breakpoint-down(sm) {
    .current-forecast {
        grid-template-columns: 1fr;
        grid-template-rows: auto 1fr 1fr;

        &__left {
            grid-column: 1 / 2;
            grid-row: 1 / 2;
        }

        &__middle {
            grid-column: 1 / 2;
            grid-row: 2 / 3;
        }

        &__right {
            grid-column: 1 / 2;
            grid-row: 3 / 4;
        }
    }
}
