@use '../../abstracts/' as *;

.form__select {
    --#{$prefix}form-select-bg-img: #{$form-select-indicator};

    display: block;
    width: 100%;
    padding: $form-select-padding-y $form-select-indicator-padding $form-select-padding-y $form-select-padding-x;
    font-family: $form-select-font-family;
    font-size: $form-select-font-size;
    font-weight: $form-select-font-weight;
    line-height: $form-select-line-height;
    color: $form-select-color;
    appearance: none;
    background-color: $form-select-bg;
    background-image: var(--#{$prefix}form-select-bg-img), var(--#{$prefix}form-select-bg-icon, none);
    background-repeat: no-repeat;
    background-position: $form-select-bg-position;
    background-size: $form-select-bg-size;
    border: $form-select-border-width solid $form-select-border-color;
    @include border-radius($form-select-border-radius, 0);
    box-shadow: $form-select-box-shadow;
    @include transition($form-select-transition);

    &:focus {
        border-color: $form-select-focus-border-color;
        outline: 0;
        box-shadow: $form-select-focus-box-shadow;
    }

    &[multiple],
    &[size]:not([size="1"]) {
        padding-right: $form-select-padding-x;
        background-image: none;
    }
  
    &:disabled {
        color: $form-select-disabled-color;
        background-color: $form-select-disabled-bg;
        border-color: $form-select-disabled-border-color;
    }
  
    &:-moz-focusring {
        color: transparent;
        text-shadow: 0 0 0 $form-select-color;
    }

    &--sm {
        padding-top: $form-select-padding-y-sm;
        padding-bottom: $form-select-padding-y-sm;
        padding-left: $form-select-padding-x-sm;
        font-size: $form-select-font-size-sm;
        @include border-radius($form-select-border-radius-sm);
    }

    &--lg {
        padding-top: $form-select-padding-y-lg;
        padding-bottom: $form-select-padding-y-lg;
        padding-left: $form-select-padding-x-lg;
        font-size: $form-select-font-size-lg;
        @include border-radius($form-select-border-radius-lg);
    }
}


@if $enable-dark-mode {
    @include color-mode(dark) {
        .form__select {
            --#{$prefix}form-select-bg-img: #{$form-select-indicator-dark};
        }
    }
}
