$ff-architects-daughter: 'Architects Daughter', cursive !default;
$ff-allison: 'Allison', cursive !default;
$ff-mktg:  "Mona Sans","Mona Sans Fallback",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";

$small-font-size:                                       .875em !default;

$font-size-root:                                        null !default;
$font-size-base:                                        1rem !default;
$font-size-xs:                                          $font-size-base * 1.2 !default; // * .75
$font-size-sm:                                          $font-size-base * 1.4 !default; // * .875
$font-size-lg:                                          $font-size-base * 2 !default; // * .1.25
$font-size-xl:                                          $font-size-base * 2.6 !default; // * .1.625

$font-weight-100:                                       100 !default;
$font-weight-200:                                       200 !default;
$font-weight-300:                                       300 !default;
$font-weight-400:                                       400 !default;
$font-weight-500:                                       500 !default;
$font-weight-600:                                       600 !default;
$font-weight-700:                                       700 !default;
$font-weight-800:                                       800 !default;
$font-weight-900:                                       900 !default;
$font-weight-base:                                      $font-weight-400;

$line-height-normal:                                    normal !default;
$line-height-base:                                      1.5 !default;
$line-height-sm:                                        1.25 !default;
$line-height-lg:                                        2 !default;

$h1-font-size:                                          $font-size-base * 2.5 !default;
$h2-font-size:                                          $font-size-base * 2 !default;
$h3-font-size:                                          $font-size-base * 1.75 !default;
$h4-font-size:                                          $font-size-base * 1.5 !default;
$h5-font-size:                                          $font-size-base * 1.25 !default;
$h6-font-size:                                          $font-size-base !default;

$font-sizes: (
    1: $h1-font-size,
    2: $h2-font-size,
    3: $h3-font-size,
    4: $h4-font-size,
    5: $h5-font-size,
    6: $h6-font-size,
    10: 1rem,
    12: 1.2rem,
    14: 1.4rem,
    16: 1.6rem,
    18: 1.8rem,
    20: 2rem,
    24: 2.4rem,
    26: 2.6rem,
    28: 2.8rem,
    30: 3rem,
    32: 3.2rem,
    36: 3.6rem,
    40: 4rem,
    44: 4.4rem,
    48: 4.8rem,
    50: 5rem,
    56: 5.6rem,
    64: 6.4rem,
    72: 7.2rem,
    75: 7.5rem,
    80: 8rem,
    90: 9rem,
    100: 10rem
) !default;
