@use '../../abstracts/' as *;

.form__input {
    display: block;
    width: 100%;
    padding: $input-padding-y $input-padding-x;
    font-family: $input-font-family;
    font-size: $input-font-size;
    font-weight: $input-font-weight;
    line-height: $input-line-height;
    color: $input-color;
    appearance: none;
    background-color: $input-bg;
    background-clip: padding-box;
    border: $input-border-width solid $input-border-color;
    @include border-radius($input-border-radius, 0);
    box-shadow: $input-box-shadow;
    @include transition($input-transition);

    &:focus {
        color: $input-focus-color;
        background: $input-focus-bg;
        border-color: $input-focus-border-color;
        outline: 0;
        box-shadow: $input-focus-box-shadow;
    }

    &::placeholder {
        color: $input-placeholder-color;
        opacity: 1;
    }

    &:disabled,
    &.disabled {
        color: $input-disabled-color;
        background: $input-disabled-bg;
        border-color: $input-disabled-border-color;
        opacity: 1;
        pointer-events: none;
    }

    &[type="file"] {
        overflow: hidden;

        &:not(:disabled):not([readonly]) {
            cursor: pointer;
        }
    }

    &::file-selector-button {
        padding: $input-padding-y $input-padding-x;
        margin: (-$input-padding-y) (-$input-padding-x);
        margin-inline-end: $input-padding-x;
        color: $form-file-button-color;
        @include gradient-bg($form-file-button-bg);
        pointer-events: none;
        border-color: inherit;
        border-style: solid;
        border-width: 0;
        border-inline-end-width: $input-border-width;
        border-radius: 0;
        @include transition($btn-transition);
    }

    &:hover:not(:disabled):not([readonly])::file-selector-button {
        background: $form-file-button-hover-bg;
    }

    &::-webkit-date-and-time-value {
        min-width: 8.5rem;
        height: if(unit($input-line-height) == '', $input-line-height * 1.6em, $input-line-height);
        margin: 0;
    }

    &::-webkit-datetime-edit {
        display: block;
        padding: 0;
    }
}

.form__input-plaintext {
    display: block;
    width: 100%;
    padding: $input-padding-y 0;
    line-height: $input-line-height;
    color: $input-plaintext-color;
    background: transparent;
    border: solid transparent;
    border-width: $input-border-width 0;

    &:focus {
        outline: 0;
    }

    &.form__input--sm,
    &.form__input--lg {
        padding-right: 0;
        padding-left: 0;
    }
}

.form__input--sm {
    min-height: $input-height-sm;
    padding: $input-padding-y-sm $input-padding-x-sm;
    font-size: $input-font-size-sm;
    @include border-radius($input-border-radius-sm);

    &::file-selector-button {
        padding: $input-padding-y-sm $input-padding-x-sm;
        margin: (-$input-padding-y-sm) (-$input-padding-x-sm);
        margin-inline-end: $input-padding-x-sm;
    }
}

.form__input--lg {
    min-height: $input-height-lg;
    padding: $input-padding-y-lg $input-padding-x-lg;
    font-size: $input-font-size-lg;
    @include border-radius($input-border-radius-lg);

    &::file-selector-button {
        padding: $input-padding-y-lg $input-padding-x-lg;
        margin: (-$input-padding-y-lg) (-$input-padding-x-lg);
        margin-inline-end: $input-padding-x-lg;
    }
}

textarea {
    &.form__input {
        min-height: $input-height;

        &--sm {
            min-height: $input-height-sm;
        }

        &--lg {
            min-height: $input-height-lg;
        }
    }
}
