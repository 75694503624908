@use '../abstracts/' as *;

.aqi {
    display: flex;
    align-items: center;
    gap: $spacer;
    margin-bottom: $spacer;

    &__text {

    }

    &__dot {
        &--good {
            color: var(--#{$prefix}success);
        }
    
        &--moderate {
            color: var(--#{$prefix}warning);
        }
    
        &--unhealthy {
            color: #ff5050;
        }
    
        &--very-unhealthy {
            color: var(--#{$prefix}danger);
        }
    
        &--hazardous {
            color: #6610f2;
        }
    }
}
