.content-grid {
    --padding-inline: 1.6rem;
    --content-max-width: 90rem;
    --breakout-max-width: 120rem;
  
    --breakout-size: calc(
        (var(--breakout-max-width) - var(--content-max-width)) / 2
    );
  
    display: grid;
    grid-template-columns:
        [full-width-start] minmax(var(--padding-inline), 1fr)
        [breakout-start] minmax(0, var(--breakout-size))
        [content-start] min(
            100% - (var(--padding-inline) * 2),
            var(--content-max-width)
        )
        [content-end]
        minmax(0, var(--breakout-size)) [breakout-end]
        minmax(var(--padding-inline), 1fr) [full-width-end];
}

.content-grid > :not(.breakout, .full-width),
.full-width > :not(.breakout, .full-width) {
    grid-column: content;
}

.content-grid > .breakout {
    grid-column: breakout;
}

.content-grid > .full-width {
    grid-column: full-width;

    display: grid;
    grid-template-columns: inherit;
}

img.full-width {
    width: 100%;
    min-height: 100%;
    object-fit: cover;
}
