@use '../abstracts/' as *;

.g-card {
    margin: 0 auto;

    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(16.5rem, 1fr));
    grid-auto-rows: auto;
    gap: 1rem;
}

%css-vars {
    --#{$prefix}card-spacer-y: #{$card-spacer-y};
    --#{$prefix}card-spacer-x: #{$card-spacer-x};
    --#{$prefix}card-title-spacer-y: #{$card-title-spacer-y};
    --#{$prefix}card-title-color: #{$card-title-color};
    --#{$prefix}card-subtitle-color: #{$card-subtitle-color};
    --#{$prefix}card-border-width: #{$card-border-width};
    --#{$prefix}card-border-color: #{$card-border-color};
    --#{$prefix}card-border-radius: #{$card-border-radius};
    --#{$prefix}card-box-shadow: #{$card-box-shadow};
    --#{$prefix}card-inner-border-radius: #{$card-inner-border-radius};
    --#{$prefix}card-cap-padding-y: #{$card-cap-padding-y};
    --#{$prefix}card-cap-padding-x: #{$card-cap-padding-x};
    --#{$prefix}card-cap-bg: #{$card-cap-bg};
    --#{$prefix}card-cap-color: #{$card-cap-color};
    --#{$prefix}card-height: #{$card-height};
    --#{$prefix}card-color: #{$card-color};
    --#{$prefix}card-bg: #{$card-bg};
    --#{$prefix}card-img-overlay-padding: #{$card-img-overlay-padding};
}

.card {
    @extend %css-vars;

    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    height: var(--#{$prefix}card-height);
    color: var(--#{$prefix}body-color);
    word-wrap: break-word;
    background-color: var(--#{$prefix}card-bg);
    background-clip: border-box;
    border: var(--#{$prefix}card-border-width) solid var(--#{$prefix}card-border-color);
    @include border-radius(var(--#{$prefix}card-border-radius));
    box-shadow: var(--#{$prefix}card-box-shadow);

    > hr {
        margin-right: 0;
        margin-left: 0;
    }

    > .list-group {
        border-top: inherit;
        border-bottom: inherit;

        &:first-child {
            border-top-width: 0;
            @include border-top-radius(var(--#{$prefix}card-inner-border-radius));
        }
    
        &:last-child  {
            border-bottom-width: 0;
            @include border-bottom-radius(var(--#{$prefix}card-inner-border-radius));
        }
    }

    > .card__header + .list-group,
    > .list-group + .card__footer {
        border-top: 0;
    }
}

.card__header {
    padding: var(--#{$prefix}card-cap-padding-y) var(--#{$prefix}card-cap-padding-x);
    margin-bottom: 0;
    color: var(--#{$prefix}card-cap-color);
    background-color: var(--#{$prefix}card-cap-bg);
    border-bottom: var(--#{$prefix}card-border-width) solid var(--#{$prefix}card-border-color);
    
    &:first-child {
        @include border-radius(var(--#{$prefix}card-inner-border-radius) var(--#{$prefix}card-inner-border-radius) 0 0);
    }
}

.card__title {
    margin-bottom: var(--#{$prefix}card-title-spacer-y);
    color: var(--#{$prefix}card-title-color);
}

.card__subtitle {
    margin-top: calc(-.5 * var(--#{$prefix}card-title-spacer-y));
    margin-bottom: 0;
    color: var(--#{$prefix}card-subtitle-color);
}

.card__body {
    flex: 1 1 auto;
    padding: var(--#{$prefix}card-spacer-y) var(--#{$prefix}card-spacer-x);
    color: var(--#{$prefix}card-color);
}

.card__text {

    &:last-child {
        margin-bottom: 0;
    }
}

.card__link {
    &:hover {
        text-decoration: if($link-hover-decoration == underline, none, null);
    }

    + .card__link {
        margin-left: var(--#{$prefix}card-spacer-x);
    }
}

.card__footer {
    padding: var(--#{$prefix}card-cap-padding-y) var(--#{$prefix}card-cap-padding-x);
    color: var(--#{$prefix}card-cap-color);
    background-color: var(--#{$prefix}card-cap-bg);
    border-top: var(--#{$prefix}card-border-width) solid var(--#{$prefix}card-border-color);
  
    &:last-child {
        @include border-radius(0 0 var(--#{$prefix}card-inner-border-radius) var(--#{$prefix}card-inner-border-radius));
    }
}

.card__header-tabs {
    margin-right: calc(-.5 * var(--#{$prefix}card-cap-padding-x));
    margin-bottom: calc(-1 * var(--#{$prefix}card-cap-padding-y));
    margin-left: calc(-.5 * var(--#{$prefix}card-cap-padding-x));
    border-bottom: 0;

    .nav__link.active {
        background-color: var(--#{$prefix}card-bg);
        border-bottom-color: var(--#{$prefix}card-bg);
    }
}

.card__img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: var(--#{$prefix}card-img-overlay-padding);
    @include border-radius(var(--#{$prefix}card-inner-border-radius));
}

.card__img,
.card__img-top,
.card__img-bottom {
    width: 100%;
}

.card__img,
.card__img-top {
    @include border-top-radius(var(--#{$prefix}card-inner-border-radius));
}

.card__img,
.card__img-bottom {
    @include border-bottom-radius(var(--#{$prefix}card-inner-border-radius));
}