@use '../abstracts/' as *;

.fade {
    @include transition($transition-fade);

    &:not(.show) {
        opacity: 0;
    }
}

.collapse {
    &:not(.show) {
        display: none;
    }
}

.collapsing {
    height: 0;
    overflow: hidden;
    @include transition($transition-collapse);

    &.collapse-horizontal {
        width: 0;
        height: auto;
        @include transition($transition-collapse-width);
    }
}
