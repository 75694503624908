@use '../abstracts/' as *;

@import url('https://fonts.googleapis.com/css2?family=Architects+Daughter&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Allison&display=swap');
@import url('https://fonts.googleapis.com/css2?family=VT323&display=swap');
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.2/font/bootstrap-icons.min.css");

html {
    // *------------------------------------------------------------* //
    // * 100% is 16px if font size is set to the default value;     * //
    // * 62.5% of 16px is 10px, so 1rem = 10px.                     * //
    // *------------------------------------------------------------* //
    font-size: 62.5%;
    scroll-behavior: smooth;
}

.page {
    --#{$prefix}body-transition-delay: 1s;

    font-family: 'Inter', sans-serif;
    font-size: 1.4rem;
    color: var(--#{$prefix}body-color);
    background: var(--#{$prefix}body-bg);
    transition: var(--#{$prefix}body-transition-delay);

    scrollbar-width: none;

    &:-webkit-scrollbar {
        display: none;
    }

    &__app {
        height: 100vh;
        height: 100dvh;

        display: grid;
        grid-template-rows: auto 1fr auto;
    }
}

.header {
    grid-row: 1 / 2;
}

.main {
    grid-row: 2 / 3;
    padding-top: 6.5rem;
}

.footer {
    grid-row: 3 / 4;
}


label {
    display: inline-block;
}

kbd {
    --#{$prefix}kbd-opacity: .09;

    display: inline-block;
    background-color: rgba(0, 0, 0, var(--#{$prefix}kbd-opacity));
    padding: 0 .4rem;
    font-size: .85em;
    letter-spacing: -.05rem;
    text-align: center;
    vertical-align: middle;
    cursor: default;
    @include border-radius(calc(var(--#{$prefix}border-radius-sm) - .2rem));
}


@if $enable-dark-mode {
    @include color-mode(dark) {
        kbd {
            --#{$prefix}kbd-opacity: 1;
        }
    }
}