@use '../abstracts/' as *;

*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

label {

}

input {
    font: inherit;
    border: none;
    outline: none;
}

textarea {
    resize: none;
}

button {
    border: none;
    background: initial;
    outline: none;  // ---- don`t forget indicator for focus state. ---- //
}

ul,
ol {
    list-style: none;
}

a {
    color: inherit;
    text-decoration: none;
    outline: none;
}

img,
svg {
    vertical-align: middle;
}

table {
    caption-side: bottom;
    border-collapse: collapse;
}

caption {
    text-align: left;
}

th {
    font-weight: $font-weight-400;
    text-align: inherit;
}

thead,
tbody,
tfoot,
tr,
td,
th {
    border-color: inherit;
    border-style: solid;
    border-width: 0;
}
