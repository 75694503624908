@use '../abstracts/' as *;

@if $enable-container-classes {
    .container,
    .container-fluid {
        @include make-container();
    }

    @each $breakpoint, $container-max-width in $container-max-widths {
        .container-#{$breakpoint} {
            @extend .container-fluid;
        }

        @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
            %responsive-container-#{$breakpoint} {
                max-width: $container-max-width;
            }

            $extend-breakpoint: true;

            @each $name, $width in $grid-breakpoints {
                @if ($extend-breakpoint) {
                    .container#{breakpoint-infix($name, $grid-breakpoints)} {
                        @extend %responsive-container-#{$breakpoint};
                    }

                    @if ($breakpoint == $name) {
                        $extend-breakpoint: false;
                    }
                }
            }
        }
    }
}
