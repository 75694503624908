@use '../abstracts/' as *;


%css-vars {

}

.spinner {

    &__border,
    &__grow {
        display: inline-block;
        width: var(--#{$prefix}spinner-width);
        height: var(--#{$prefix}spinner-height);
        vertical-align: var(--#{$prefix}spinner-vertical-align);
        border-radius: 50%;
        animation: var(--#{$prefix}spinner-animation-speed) linear infinite var(--#{$prefix}spinner-animation-name);
    }
    &__border {
        --#{$prefix}spinner-width: #{$spinner-width};
        --#{$prefix}spinner-height: #{$spinner-height};
        --#{$prefix}spinner-vertical-align: #{$spinner-vertical-align};
        --#{$prefix}spinner-border-width: #{$spinner-border-width};
        --#{$prefix}spinner-animation-speed: #{$spinner-animation-speed};
        --#{$prefix}spinner-animation-name: spinner-border;

        border: var(--#{$prefix}spinner-border-width) solid currentcolor;
        border-right-color: transparent;

        &--sm {
            --#{$prefix}spinner-width: #{$spinner-width-sm};
            --#{$prefix}spinner-height: #{$spinner-height-sm};
            --#{$prefix}spinner-border-width: #{$spinner-border-width-sm};
        }
    }

    &__grow {
        --#{$prefix}spinner-width: #{$spinner-width};
        --#{$prefix}spinner-height: #{$spinner-height};
        --#{$prefix}spinner-vertical-align: #{$spinner-vertical-align};
        --#{$prefix}spinner-animation-speed: #{$spinner-animation-speed};
        --#{$prefix}spinner-animation-name: spinner-grow;

        background-color: currentcolor;
        opacity: 0;

        &--sm {
            --#{$prefix}spinner-width: #{$spinner-width-sm};
            --#{$prefix}spinner-height: #{$spinner-height-sm};
        }
    }
}

@keyframes spinner-border {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes spinner-grow {
    0% {
        transform: scale(0);
    }
    50% {
        opacity: 1;
        transform: none;
    }
}


@if $enable-reduced-motion {
    @media (prefers-reduced-motion: reduce) {
        .spinner-border,
        .spinner-grow {
            --#{$prefix}spinner-animation-speed: #{$spinner-animation-speed * 2};
        }
    }
}
