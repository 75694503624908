@use '../abstracts/' as *;
@use '../components/' as *;

.city {

    &-list {
        @extend .list-group;
    }

    &-item {
        @extend .list-group__item;
        cursor: pointer;

        &__top {
            display: flex;
            align-items: center;
            gap: .5rem;
            margin-bottom: .3rem;
        }

        &__img {
            width: 4rem;
            height: 4rem;
        }

        &__bottom {
            display: flex;
            align-items: center;
            gap: .5rem;
            font-size: 1rem;
            color: var(--#{$prefix}secondary-color);
            user-select: none;
        }

        &__city {

        }

        &__cords {

        }

    }
}
