@use '../abstracts/' as *;

.badge {
    --#{$prefix}badge-padding-x: #{$badge-padding-x};
    --#{$prefix}badge-padding-y: #{$badge-padding-y};
    --#{$prefix}bade-font-size: #{$badge-font-size};
    --#{$prefix}badge-font-weight: #{$badge-font-weight};
    --#{$prefix}badge-color: #{$badge-color};
    --#{$prefix}badge-border-radius: #{$badge-border-radius};

    display: inline-block;
    padding: var(--#{$prefix}badge-padding-y) var(--#{$prefix}badge-padding-x);
    font-size: var(--#{$prefix}bade-font-size);
    font-weight: var(--#{$prefix}badge-font-weight);
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    @include border-radius(var(--#{$prefix}badge-border-radius));
    @include gradient-bg();
    
    &:empty {
        display: none;
    }
}

.btn .badge {
    position: relative;
    top: -.1rem;
}
