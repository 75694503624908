@use '../../abstracts/' as *;

.form__floating {
    position: relative;

    > .form__input,
    > .form__input-plaintext,
    > .form__select {
        height: $form-floating-height;
        min-height: $form-floating-height;
        line-height: $form-floating-line-height;
    }

    > label {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        height: 100%;
        padding: $form-floating-padding-y $form-floating-padding-x;
        overflow: hidden;
        text-align: start;
        text-overflow: ellipsis;
        white-space: nowrap;
        pointer-events: none;
        border: $input-border-width solid transparent;
        transform-origin: 0 0;
        @include transition($form-floating-transition);
    }

    > .form__input,
    > .form__input-plaintext {
        padding: $form-floating-padding-y $form-floating-padding-x;

        &::placeholder {
            color: transparent;
        }

        &:focus,
        &:not(:placeholder-shown) {
            padding-top: $form-floating-input-padding-t;
            padding-bottom: $form-floating-input-padding-b;
        }
        &:-webkit-autofill {
            padding-top: $form-floating-input-padding-t;
            padding-bottom: $form-floating-input-padding-b;
        }
    }

    > .form__select {
        padding-top: $form-floating-input-padding-t;
        padding-bottom: $form-floating-input-padding-b;
    }

    > .form__input:focus,
    > .form__input:not(:placeholder-shown),
    > .form__input-plaintext,
    > .form__select {
        ~ label {
            color: rgba(var(--#{$prefix}body-color-rgb), #{$form-floating-label-opacity});
            font-size: $form-floating-font-size;
            transform: $form-floating-label-transform;

            &::after {
                position: absolute;
                inset: $form-floating-padding-y ($form-floating-padding-x * .5);
                z-index: -1;
                height: ($form-floating-label-height - .2);
                content: '';
                background-color: input-bg;
                @include border-radius($input-border-radius);
            }
        }
    }

    > .form-control:-webkit-autofill {
        ~ label {
            color: rgba(var(--#{$prefix}body-color-rgb), #{$form-floating-label-opacity});
            transform: $form-floating-label-transform;
        }
    }
    
    > .form-control-plaintext {
        ~ label {
            border-width: $input-border-width 0;
        }
    }

    > :disabled ~ label,
    > .form__input:disabled ~ label {
        color: $form-floating-label-disabled-color;
        
        &::after {
            background-color: $input-disabled-bg;
        }
    }
}
