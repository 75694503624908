@use '../abstracts/' as *;

.fixed-top {
    position: fixed !important;
    top: 0;
    right: 0;
    left: 0;
    z-index: $zindex-fixed;
}

.fixed-bottom {
    position: fixed !important;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: $zindex-fixed;
}

@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        .sticky#{$infix}-top {
            position: sticky;
            top: 0;
            z-index: $zindex-sticky;
        }

        .sticky#{$infix}-bottom {
            position: sticky;
            bottom: 0;
            z-index: $zindex-sticky;
        }
    }
}
