@use './typography' as *;

$prefix:                                                            jsx- !default;


$min-contrast-ratio:                                                4.5 !default;
$color-contrast-dark:                                               var(--color-white-000) !default;
$color-contrast-light:                                              var(--color-black-000) !default;


$enable-caret:                                                      true !default;
$enable-rounded:                                                    true !default;
$enable-shadows:                                                    false !default;
$enable-gradients:                                                  false !default;
$enable-transitions:                                                true !default;
$enable-reduced-motion:                                             true !default;
$enable-smooth-scroll:                                              true !default;
$enable-grid-classes:                                               true !default;
$enable-container-classes:                                          true !default;
$enable-cssgrid:                                                    true !default;
$enable-button-pointers:                                            true !default;
$enable-rfs:                                                        true !default;
$enable-validation-icons:                                           true !default;
$enable-negative-margins:                                           true !default;
$enable-deprecation-messages:                                       true !default;
$enable-important-utilities:                                        true !default;

$enable-dark-mode:                                                  true !default;
$color-mode-type:                                                   data !default;


$gradient:                                                          linear-gradient(180deg, rgba(var(--color-white-000-rgb), .15), rgba(var(--color-white-000-rgb), 0)) !default;


$spacer:                                                            1.6rem !default;
$spacers: (
    0: 0,
    1: $spacer * .25,
    2: $spacer * .5,
    3: $spacer,
    4: $spacer * 1.5,
    5: $spacer * 3
) !default;

$negative-spacer:                                                   -1.6rem !default;
$negative-spacers: (
    0: 0,
    n1: $negative-spacer * .25,
    n2: $negative-spacer * .5,
    n3: $negative-spacer,
    n4: $negative-spacer * 1.5,
    n5: $negative-spacer * 3
) !default;


$position-values: (
    0: 0,
    25: 25%,
    50: 50%,
    75: 75%,
    100: 100%
) !default;


$body-color:                                                        var(--color-gray-900) !default;
$body-bg:                                                           var(--color-white-000) !default;

$body-secondary-color:                                              rgba(var(--color-gray-900-rgb), .75) !default;
$body-secondary-bg:                                                 var(--color-gray-200) !default;

$body-tertiary-color:                                               rgba(var(--color-gray-900-rgb), .5) !default;
$body-tertiary-bg:                                                  var(--color-gray-100) !default;

$body-emphasis-color:                                               var(--color-black-000) !default;
$body-emphasis-color-rgb:                                           var(--color-black-000-rgb) !default;

$body-color-dark:                                                   var(--color-gray-300) !default;
$body-bg-dark:                                                      var(--color-gray-900) !default;

$body-secondary-color-dark:                                         rgba(var(--color-gray-300-rgb), .75) !default;
$body-secondary-bg-dark:                                            var(--color-gray-800) !default;

$body-tertiary-color-dark:                                          rgba(var(--color-gray-300-rgb), .5) !default;
$body-tertiary-bg-dark:                                             var(--color-gray-800-900-tertiary) !default;

$body-emphasis-color-dark:                                          var(--color-white-000) !default;
$body-emphasis-color-rgb-dark:                                      var(--color-white-000-rgb) !default;


$container-max-widths: (
  sm: 54rem, // 540px = 54rem
  md: 72rem, // 720px = 72rem
  lg: 96rem, // 960px = 96rem
  xl: 114rem, // 1140px = 114rem
  xxl: 132rem // 1320px = 132rem
) !default;

$grid-columns:                                                      12 !default;
$grid-gutter-width:                                                 1.5rem !default;
$grid-row-columns:                                                  6 !default;

$container-padding-x:                                               $grid-gutter-width !default;

$gutters: (
    0: 0,
    1: $spacer * .25,
    2: $spacer * .5,
    3: $spacer,
    4: $spacer * 1.5,
    5: $spacer * 3,
);


$border-width:                                                      .1rem !default;
$border-widths: (
    1: .1rem,
    2: .2rem,
    3: .3rem,
    4: .4rem,
    5: .5rem
) !default;

$border-style:                                                      solid !default;
$border-color:                                                      var(--color-gray-300) !default;
$border-color-translucent:                                          rgba(var(--color-black-000-rgb), .175) !default;

$border-color-dark:                                                 var(--color-gray-700) !default;
$border-color-translucent-dark:                                     rgba(var(--color-white-000-rgb), .15) !default;


$border-radius:                                                     .6rem !default;
$border-radius-sm:                                                  .4rem !default;
$border-radius-lg:                                                  .8rem !default;
$border-radius-xl:                                                  1.6rem !default;
$border-radius-xxl:                                                 3.2rem !default;
$border-radius-pill:                                                80rem !default;


$box-shadow:                                                        0 .8rem 1.6rem rgba(var(--color-black-000-rgb), .15) !default;
$box-shadow-sm:                                                     0 .2rem .4rem rgba(var(--color-black-000-rgb), .075) !default;
$box-shadow-lg:                                                     0 1.6rem 4.8rem rgba(var(--color-black-000-rgb), .175) !default;
$box-shadow-inset:                                                  inset 0 .1rem .2rem rgba(var(--color-black-000-rgb), .075) !default;


$component-active-color:                                            var(--color-white-000) !default;
$component-active-bg:                                               var(--color-blue-000) !default;


$focus-ring-width:                                                  .4rem !default;
$focus-ring-opacity:                                                .25 !default;
$focus-ring-color:                                                  rgba(var(--#{$prefix}primary-rgb), $focus-ring-opacity) !default;
$focus-ring-blur:                                                   0 !default;
$focus-ring-box-shadow:                                             0 0 $focus-ring-blur $focus-ring-width $focus-ring-color !default;


$transition-base:                                                   all .2s ease-in-out !default;
$transition-fade:                                                   opacity .15s linear !default;
$transition-collapse:                                               height .35s ease !default;
$transition-collapse-width:                                         width .35s ease !default;


$zindex-dropdown:                                                   1000 !default;
$zindex-sticky:                                                     1020 !default;
$zindex-fixed:                                                      1030 !default;
$zindex-offcanvas-backdrop:                                         1040 !default;
$zindex-offcanvas:                                                  1045 !default;
$zindex-modal-backdrop:                                             1050 !default;
$zindex-modal:                                                      1055 !default;
$zindex-popover:                                                    1070 !default;
$zindex-tooltip:                                                    1080 !default;
$zindex-toast:                                                      1090 !default;

$zindex-levels: (
    n1: -1,
    0: 0,
    1: 1,
    2: 2,
    3: 3
) !default;


// *------------------------------------* //
// *            components              * //
// *------------------------------------* //
$header-padding-y:                                                  null !default;
$header-padding-x:                                                  null !default;
$header-min-height:                                                 6.5rem !default;
$header-box-shadow:                                                 0 .2rem .5rem 0 rgba(var(--color-black-000-rgb), .5) !default;

$header-bg:                                                         $body-bg !default;
$header-bg-dark:                                                    var(--color-black-000) !default;

$header-bg-tertiary:                                                var(--#{$prefix}body-bg) !default;


$logo-margin-end:                                                   1.6rem !default;
$logo-text-font-size:                                               $font-size-lg !default;
$logo-text-font-weight:                                             null !default;

$logo-light-color:                                                   rgba(var(--color-black-000-rgb), .65) !default;
$logo-light-hover-color:                                             rgba(var(--color-black-000-rgb), 1) !default;
 
$logo-dark-color:                                                    rgba(var(--color-white-000-rgb), .75) !default;
$logo-dark-hover-color:                                              rgba(var(--color-white-000-rgb), 1) !default;


$nav-link-padding-y:                                                .8rem !default;
$nav-link-padding-x:                                                1.6rem !default;

$nav-link-gap:                                                      $nav-link-padding-x;
$nav-link-font-size:                                                null !default;
$nav-link-font-weight:                                              null !default;
$nav-link-color:                                                    var(--#{$prefix}link-color) !default;
$nav-link-hover-color:                                              var(--#{$prefix}link-hover-color) !default;
$nav-link-transition:                                               color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out !default;
$nav-link-disabled-color:                                           var(--#{$prefix}secondary-color) !default;
$nav-link-focus-box-shadow:                                         $focus-ring-box-shadow !default;

$nav-tabs-link-min-height:                                          $header-min-height !default;
$nav-tabs-link-line-height:                                         $nav-tabs-link-min-height !default;
$nav-tabs-link-hover-bg:                                            var(--color-gray-300) !default;

$nav-underline-link-height:                                         .2rem !default;
$nav-underline-link-width:                                          100% !default;
$nav-underline-link-bg:                                             var(--#{$prefix}emphasis-color) !default;
$nav-underline-link-opacity:                                        0 !default;
$nav-underline-link-transition:                                     all .4s !default;

$nav-light-color:                                                   rgba(var(--color-black-000-rgb), .65) !default;
$nav-light-hover-color:                                             rgba(var(--color-black-000-rgb), .8) !default;
$nav-light-active-color:                                            rgba(var(--color-black-000-rgb), 1) !default;
$nav-light-disabled-color:                                          rgba(var(--color-black-000-rgb), .3) !default;

$nav-dark-color:                                                    rgba(var(--color-white-000-rgb), .55) !default;
$nav-dark-hover-color:                                              rgba(var(--color-white-000-rgb), .75) !default;
$nav-dark-active-color:                                             var(--color-white-000) !default;
$nav-dark-disabled-color:                                           rgba(var(--color-white-000-rgb), .25) !default;
$nav-dark-icon-color:                                               $nav-dark-color !default;

$nav-tabs-link-hover-bg-dark:                                       var(--color-black-000) !default;

$footer-min-height:                                                 $header-min-height !default;
$footer-box-shadow:                                                 0 -.2rem .5rem 0 rgba(var(--color-black-000-rgb), .5);

$footer-bg:                                                         $header-bg !default;
$footer-bg-dark:                                                    $header-bg-dark !default;

$input-btn-padding-y:                                               .375rem !default;
$input-btn-padding-x:                                               .75rem !default;
$input-btn-font-family:                                             null !default;
$input-btn-font-size:                                               $font-size-base !default;
$input-btn-line-height:                                             $line-height-base !default;

$input-btn-focus-width:                                             $focus-ring-width !default;
$input-btn-focus-color-opacity:                                     $focus-ring-opacity !default;
$input-btn-focus-color:                                             $focus-ring-color !default;
$input-btn-focus-blur:                                              $focus-ring-blur !default;
$input-btn-focus-box-shadow:                                        $focus-ring-box-shadow !default;

$input-btn-padding-y-sm:                                            .25rem !default;
$input-btn-padding-x-sm:                                            .5rem !default;
$input-btn-font-size-sm:                                            font-size-sm !default;

$input-btn-padding-y-lg:                                            .5rem !default;
$input-btn-padding-x-lg:                                            1rem !default;
$input-btn-font-size-lg:                                            font-size-lg !default;

$input-btn-border-width:                                            var(--#{$prefix}border-width) !default;

$btn-color:                                                         var(--#{$prefix}body-color) !default;
$btn-padding-y:                                                     $input-btn-padding-y !default;
$btn-padding-x:                                                     $input-btn-padding-x !default;
$btn-font-family:                                                   $input-btn-font-family !default;
$btn-font-size:                                                     $input-btn-font-size !default;
$btn-line-height:                                                   $input-btn-line-height !default;
$btn-white-space:                                                   null !default;

$btn-padding-y-sm:                                                  $input-btn-padding-y-sm !default;
$btn-padding-x-sm:                                                  $input-btn-padding-x-sm !default;
$btn-font-size-sm:                                                  $input-btn-font-size-sm !default;

$btn-padding-y-lg:                                                  $input-btn-padding-y-lg !default;
$btn-padding-x-lg:                                                  $input-btn-padding-x-lg !default;
$btn-font-size-lg:                                                  $input-btn-font-size-lg !default;

$btn-border-width:                                                  $input-btn-border-width !default;

$btn-font-weight:                                                   $font-weight-400 !default;
$btn-box-shadow:                                                    inset 0 .1rem 0 rgba(var(--color-white-000-rgb), .15), 0 .1rem .1rem rgba(var(--color-black-000-rgb), .075) !default;
$btn-focus-width:                                                   $input-btn-focus-width !default;
$btn-focus-box-shadow:                                              $input-btn-focus-box-shadow !default;
$btn-disabled-opacity:                                              .65 !default;
$btn-active-box-shadow:                                             inset 0 .3rem .5rem rgba(var(--color-black-000-rgb), .125) !default;

$btn-link-color:                                                    var(--#{$prefix}link-color) !default;
$btn-link-hover-color:                                              var(--#{$prefix}link-hover-color) !default;
$btn-link-disabled-color:                                           var(--color-gray-600) !default;
$btn-link-focus-shadow-rgb:                                         null; // to-rgb(mix(color-contrast($link-color), $link-color, 15%)) !default;

$btn-border-radius:                                                 var(--#{$prefix}border-radius) !default;
$btn-border-radius-sm:                                              var(--#{$prefix}border-radius-sm) !default;
$btn-border-radius-lg:                                              var(--#{$prefix}border-radius-lg) !default;

$btn-transition:                                                    color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !default;

$btn-hover-bg-shade-amount:                                         15% !default;
$btn-hover-bg-tint-amount:                                          15% !default;
$btn-hover-border-shade-amount:                                     20% !default;
$btn-hover-border-tint-amount:                                      10% !default;
$btn-active-bg-shade-amount:                                        20% !default;
$btn-active-bg-tint-amount:                                         20% !default;
$btn-active-border-shade-amount:                                    25% !default;
$btn-active-border-tint-amount:                                     10% !default;


$input-padding-y:                                                   $input-btn-padding-y !default;
$input-padding-x:                                                   $input-btn-padding-x !default;
$input-font-family:                                                 $input-btn-font-family !default;
$input-font-size:                                                   $input-btn-font-size !default;
$input-font-weight:                                                 $font-weight-base !default;
$input-line-height:                                                 $input-btn-line-height !default;

$input-padding-y-sm:                                                $input-btn-padding-y-sm !default;
$input-padding-x-sm:                                                $input-btn-padding-x-sm !default;
$input-font-size-sm:                                                $input-btn-font-size-sm !default;

$input-padding-y-lg:                                                $input-btn-padding-y-lg !default;
$input-padding-x-lg:                                                $input-btn-padding-x-lg !default;
$input-font-size-lg:                                                $input-btn-font-size-lg !default;

$input-bg:                                                          var(--#{$prefix}body-bg) !default;
$input-disabled-color:                                              null !default;
$input-disabled-bg:                                                 var(--#{$prefix}secondary-bg) !default;
$input-disabled-border-color:                                       null !default;

$input-color:                                                       var(--#{$prefix}body-color) !default;
$input-border-color:                                                var(--#{$prefix}border-color) !default;
$input-border-width:                                                $input-btn-border-width !default;
$input-box-shadow:                                                  var(--#{$prefix}box-shadow-inset) !default;

$input-border-radius:                                               var(--#{$prefix}border-radius) !default;
$input-border-radius-sm:                                            var(--#{$prefix}border-radius-sm) !default;
$input-border-radius-lg:                                            var(--#{$prefix}border-radius-lg) !default;

$input-focus-bg:                                                    $input-bg !default;
$input-focus-border-color:                                          tint-color($component-active-bg, 50%) !default;
$input-focus-color:                                                 $input-color !default;
$input-focus-width:                                                 $input-btn-focus-width !default;
$input-focus-box-shadow:                                            null !default; // $input-btn-focus-box-shadow !default;

$input-placeholder-color:                                           var(--#{$prefix}secondary-color) !default;
$input-plaintext-color:                                             var(--#{$prefix}body-color) !default;

$input-height-border:                                               calc(#{$input-border-width} * 2) !default;

$input-height-inner:                                                calc(($input-line-height * 1em) + calc($input-padding-y * 2));
$input-height-inner-half:                                           calc(($input-line-height * .5em) + $input-padding-y);
$input-height-inner-quarter:                                        calc(($input-line-height * .25em) + calc($input-padding-y * .5)) !default;

$input-height:                                                      calc(($input-line-height * 1em) + calc(($input-padding-y * 2) + $input-height-border));
$input-height-sm:                                                   calc(($input-line-height * 1em) + calc(($input-padding-y-sm * 2) + $input-height-border));
$input-height-lg:                                                   calc(($input-line-height * 1em) + calc(($input-padding-y-lg * 2) + $input-height-border));

$input-transition:                                                  border-color .15s ease-in-out, box-shadow .15s ease-in-out !default;
                         
$form-color-width:                                                  3rem !default;

$form-label-margin-bottom:                                          .5rem !default;
$form-label-font-size:                                              null !default;
$form-label-font-style:                                             null !default;
$form-label-font-weight:                                            null !default;
$form-label-color:                                                  null !default;

$form-text-margin-top:                                              .25rem !default;
$form-text-font-size:                                               $small-font-size !default;
$form-text-font-style:                                              null !default;
$form-text-font-weight:                                             null !default;
$form-text-color:                                                   var(--#{$prefix}secondary-color) !default;

$form-file-button-color:                                            $input-color !default;
$form-file-button-bg:                                               var(--#{$prefix}tertiary-bg) !default;
$form-file-button-hover-bg:                                         var(--#{$prefix}secondary-bg) !default;

$form-select-padding-y:                                             $input-padding-y !default;
$form-select-padding-x:                                             $input-padding-x !default;
$form-select-font-family:                                           $input-font-family !default;
$form-select-font-size:                                             $input-font-size !default;
$form-select-indicator-padding:                                     $form-select-padding-x * 3 !default;
$form-select-font-weight:                                           $input-font-weight !default;
$form-select-line-height:                                           $input-line-height !default;
$form-select-color:                                                 $input-color !default;
$form-select-bg:                                                    $input-bg !default;
$form-select-disabled-color:                                        null !default;
$form-select-disabled-bg:                                           $input-disabled-bg !default;
$form-select-disabled-border-color:                                 $input-disabled-border-color !default;
$form-select-bg-position:                                           right $form-select-padding-x center !default;
$form-select-bg-size:                                               1.6rem 1.2rem !default;
$form-select-indicator-color:                                       var(--color-gray-800) !default;
$form-select-indicator:                                             url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#{$form-select-indicator-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/></svg>") !default;
$form-select-indicator-dark:                                        null !default;

$form-select-feedback-icon-padding-end:                             $form-select-padding-x * 2.5 + $form-select-indicator-padding !default;
$form-select-feedback-icon-position:                                center right $form-select-indicator-padding !default;
$form-select-feedback-icon-size:                                    $input-height-inner-half $input-height-inner-half !default;

$form-select-border-width:                                          $input-border-width !default;
$form-select-border-color:                                          $input-border-color !default;
$form-select-border-radius:                                         $input-border-radius !default;
$form-select-box-shadow:                                            var(--#{$prefix}box-shadow-inset) !default;

$form-select-focus-border-color:                                    $input-focus-border-color !default;
$form-select-focus-width:                                           $input-focus-width !default;
$form-select-focus-box-shadow:                                      null !default; // 0 0 0 $form-select-focus-width $input-btn-focus-color !default;

$form-select-padding-y-sm:                                          $input-padding-y-sm !default;
$form-select-padding-x-sm:                                          $input-padding-x-sm !default;
$form-select-font-size-sm:                                          $input-font-size-sm !default;
$form-select-border-radius-sm:                                      $input-border-radius-sm !default;

$form-select-padding-y-lg:                                          $input-padding-y-lg !default;
$form-select-padding-x-lg:                                          $input-padding-x-lg !default;
$form-select-font-size-lg:                                          $input-font-size-lg !default;
$form-select-border-radius-lg:                                      $input-border-radius-lg !default;

$form-select-transition:                                            $input-transition !default;


$form-check-input-width:                                            1em !default;
$form-check-min-height:                                             $font-size-base * $line-height-base !default;
$form-check-padding-start:                                          $form-check-input-width + .5em !default;
$form-check-margin-bottom:                                          .125rem !default;
$form-check-label-color:                                            null !default;
$form-check-label-cursor:                                           null !default;
$form-check-transition:                                             null !default;

$form-check-input-active-filter:                                    brightness(90%) !default;

$form-check-input-bg:                                               $input-bg;
$form-check-input-border:                                           var(--#{$prefix}border-width) solid var(--#{$prefix}border-color);
$form-check-input-border-radius:                                    .25em;
$form-check-radio-border-radius:                                    50%;
$form-check-input-focus-border:                                     $input-focus-border-color;
$form-check-input-focus-box-shadow:                                 null !default; //$focus-ring-box-shadow;

$form-check-input-checked-color:                                    $component-active-color;
$form-check-input-checked-bg-color:                                 $component-active-bg;
$form-check-input-checked-border-color:                             $form-check-input-checked-bg-color;
$form-check-input-checked-bg-image:                                 url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$form-check-input-checked-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/></svg>");
$form-check-radio-checked-bg-image:                                 url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='2' fill='#{$form-check-input-checked-color}'/></svg>");

$form-check-input-indeterminate-color:                              $component-active-color;
$form-check-input-indeterminate-bg-color:                           $component-active-bg;
$form-check-input-indeterminate-border-color:                       $form-check-input-indeterminate-bg-color;
$form-check-input-indeterminate-bg-image:                           url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$form-check-input-indeterminate-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/></svg>");

$form-check-input-disabled-opacity:                                 .5 !default;
$form-check-label-disabled-opacity:                                 $form-check-input-disabled-opacity !default;
$form-check-btn-check-disabled-opacity:                             $btn-disabled-opacity !default;

$form-check-inline-margin-end:                                      1.6rem !default;

$form-switch-color:                                                 rgba(var(--color-black-000-rgb), .25) !default;
$form-switch-width:                                                 2em !default;
$form-switch-padding-start:                                         $form-switch-width + .5em !default;
$form-switch-bg-image:                                              url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-color}'/></svg>") !default;
$form-switch-border-radius:                                         $form-switch-width !default;
$form-switch-transition:                                            background-position .15s ease-in-out !default;

$form-switch-bg-image-dark:                                         null !default;

$form-switch-focus-color:                                           $input-focus-border-color !default;
$form-switch-focus-bg-image:                                        url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-focus-color}'/></svg>") !default;

$form-switch-checked-color:                                         $component-active-color !default;
$form-switch-checked-bg-image:                                      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-checked-color}'/></svg>") !default;
$form-switch-checked-bg-position:                                   right center !default;

$form-range-track-width:                                            100% !default;
$form-range-track-height:                                           .5rem !default;
$form-range-track-cursor:                                           pointer !default;
$form-range-track-bg:                                               var(--#{$prefix}secondary-bg) !default;
$form-range-track-border-radius:                                    1.6rem !default;
$form-range-track-box-shadow:                                       var(--#{$prefix}box-shadow-inset) !default;

$form-range-thumb-width:                                            1.6rem !default;
$form-range-thumb-height:                                           $form-range-thumb-width !default;
$form-range-thumb-bg:                                               $component-active-bg !default;
$form-range-thumb-border:                                           0 !default;
$form-range-thumb-border-radius:                                    1.6rem !default;
$form-range-thumb-box-shadow:                                       0 .16rem .4rem rgba(var(--color-black-000-rgb), .5) !default;
$form-range-thumb-focus-box-shadow:                                 0 0 0 .1rem $body-bg, $input-focus-box-shadow !default;
$form-range-thumb-focus-box-shadow-width:                           $input-focus-width !default;
$form-range-thumb-active-bg:                                        rgba(var(--color-blue-000-rgb), .4);
$form-range-thumb-disabled-bg:                                      var(--#{$prefix}secondary-color) !default;
$form-range-thumb-transition:                                       background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !default;

$input-group-addon-padding-y:                                       $input-padding-y !default;
$input-group-addon-padding-x:                                       $input-padding-x !default;
$input-group-addon-font-weight:                                     $input-font-weight !default;
$input-group-addon-color:                                           $input-color !default;
$input-group-addon-bg:                                              var(--#{$prefix}tertiary-bg) !default;
$input-group-addon-border-color:                                    $input-border-color !default;

$form-floating-font-size:                                           1.2rem !default;
$form-floating-height:                                              calc(3.5rem + $input-height-border) !default;
$form-floating-line-height:                                         1.25 !default;
$form-floating-padding-x:                                           $input-padding-x !default;
$form-floating-padding-y:                                           1rem !default;
$form-floating-input-padding-t:                                     1.625rem !default;
$form-floating-input-padding-b:                                     .625rem !default;
$form-floating-label-height:                                        1.5em !default;
$form-floating-label-opacity:                                       .65 !default;
$form-floating-label-transform:                                     scale(.85) translateY(-.5rem) translateX(.15rem) !default;
$form-floating-label-disabled-color:                                var(--color-gray-600) !default;
$form-floating-transition:                                          opacity .1s ease-in-out, transform .1s ease-in-out !default;

// Accordion
$accordion-padding-y:                                               1.6rem !default;
$accordion-padding-x:                                               2rem !default;
$accordion-color:                                                   var(--#{$prefix}body-color) !default;
$accordion-bg:                                                      var(--#{$prefix}body-bg) !default;
$accordion-border-width:                                            var(--#{$prefix}border-width) !default;
$accordion-border-color:                                            var(--#{$prefix}border-color) !default;
$accordion-border-radius:                                           var(--#{$prefix}border-radius) !default;
$accordion-inner-border-radius:                                     calc($accordion-border-radius - $accordion-border-width) !default;

$accordion-body-padding-y:                                          $accordion-padding-y !default;
$accordion-body-padding-x:                                          $accordion-padding-x !default;

$accordion-button-padding-y:                                        $accordion-padding-y !default;
$accordion-button-padding-x:                                        $accordion-padding-x !default;
$accordion-button-color:                                            var(--#{$prefix}body-color) !default;
$accordion-button-bg:                                               var(--#{$prefix}accordion-bg) !default;
$accordion-transition:                                              $btn-transition, border-radius .15s ease !default;
$accordion-button-active-bg:                                        var(--#{$prefix}primary-bg-subtle) !default;
$accordion-button-active-color:                                     var(--#{$prefix}primary-text-emphasis) !default;

$accordion-button-focus-border-color:                               $input-focus-border-color !default;
$accordion-button-focus-box-shadow:                                 $btn-focus-box-shadow !default;

$accordion-icon-width:                                              1.25rem !default;
$accordion-icon-color:                                              $body-color !default;
$accordion-icon-active-color:                                       var(--#{$prefix}primary-text-emphasis) !default;
$accordion-icon-transition:                                         transform .2s ease-in-out !default;
$accordion-icon-transform:                                          rotate(-180deg) !default;

$accordion-button-icon:                                             url('https://raw.githubusercontent.com/JcSilverX/iconsx/9e7805d63a7d15ed7d0ef4b3324719aebaa740f6/light/chevron-down.svg') !default; // url('../../images/icons/light/chevron-down.svg') !default;
$accordion-button-active-icon:                                      url('https://raw.githubusercontent.com/JcSilverX/iconsx/9e7805d63a7d15ed7d0ef4b3324719aebaa740f6/light/chevron-up.svg') !default; // url('../../images/icons/light/chevron-up.svg') !default;

$accordion-icon-color-dark:                                         var(--#{$prefix}primary-text-emphasis) !default;
$accordion-icon-active-color-dark:                                  var(--#{$prefix}primary-text-emphasis) !default;

$accordion-button-icon-dark:                                        url('https://raw.githubusercontent.com/JcSilverX/iconsx/b6b0ea18eafa248adc0cc030f988ace322c0d6ff/chevron-down.svg') !default;
$accordion-button-active-icon-dark:                                 url('https://raw.githubusercontent.com/JcSilverX/iconsx/9e7805d63a7d15ed7d0ef4b3324719aebaa740f6/dark/chevron-up.svg') !default;;

$alert-padding-y:                                                   $spacer !default;
$alert-padding-x:                                                   $spacer !default;
$alert-margin-bottom:                                               1.6rem !default;
$alert-border-radius:                                               var(--#{$prefix}border-radius) !default;
$alert-link-font-weight:                                            $font-weight-900 !default;
$alert-border-width:                                                var(--#{$prefix}border-width) !default;
$alert-dismissible-padding-r:                                       $alert-padding-x * 3 !default;

$badge-font-size:                                                   .75em !default;
$badge-font-weight:                                                 $font-weight-900 !default;
$badge-color:                                                       var(--color-white-000) !default;
$badge-padding-y:                                                   .35em !default;
$badge-padding-x:                                                   .65em !default;
$badge-border-radius:                                               var(--#{$prefix}border-radius) !default;

$breadcrumb-font-size:                                              null !default;
$breadcrumb-padding-y:                                              0 !default;
$breadcrumb-padding-x:                                              0 !default;
$breadcrumb-item-padding-x:                                         .8rem !default;
$breadcrumb-margin-bottom:                                          1rem !default;
$breadcrumb-bg:                                                     null !default;
$breadcrumb-divider-color:                                          var(--#{$prefix}secondary-color) !default;
$breadcrumb-active-color:                                           var(--#{$prefix}secondary-color) !default;
$breadcrumb-divider:                                                '/' !default;
$breadcrumb-divider-flipped:                                        $breadcrumb-divider !default;
$breadcrumb-border-radius:                                          null !default;

$btn-close-width:                                                   1em !default;
$btn-close-height:                                                  $btn-close-width !default;
$btn-close-padding-x:                                               .25em !default;
$btn-close-padding-y:                                               $btn-close-padding-x !default;
$btn-close-color:                                                   var(--color-black-000) !default;
$btn-close-bg:                                                      url('https://raw.githubusercontent.com/JcSilverX/iconsx/b6b0ea18eafa248adc0cc030f988ace322c0d6ff/light/x-lg.svg') !default;
$btn-close-focus-shadow:                                            $focus-ring-box-shadow !default;
$btn-close-opacity:                                                 .5 !default;
$btn-close-transition:                                              all .4s;
$btn-close-hover-opacity:                                           .75 !default;
$btn-close-focus-opacity:                                           1 !default;
$btn-close-disabled-opacity:                                        .25 !default;
$btn-close-white-filter:                                            invert(1) grayscale(100%) brightness(200%) !default;

$card-spacer-y:                                                     $spacer !default;
$card-spacer-x:                                                     $spacer !default;
$card-title-spacer-y:                                               $spacer * .5 !default;
$card-title-color:                                                  null !default;
$card-subtitle-color:                                               null !default;
$card-border-width:                                                 var(--#{$prefix}border-width) !default;
$card-border-color:                                                 var(--#{$prefix}border-color-translucent) !default;
$card-border-radius:                                                var(--#{$prefix}border-radius) !default;
$card-box-shadow:                                                   null !default;
$card-inner-border-radius:                                          subtract($card-border-radius, $card-border-width) !default;
$card-cap-padding-y:                                                $card-spacer-y * .5 !default;
$card-cap-padding-x:                                                $card-spacer-x !default;
$card-cap-bg:                                                       rgba(var(--#{$prefix}body-color-rgb), .03) !default;
$card-cap-color:                                                    null !default;
$card-height:                                                       null !default;
$card-color:                                                        null !default;
$card-bg:                                                           var(--#{$prefix}body-bg) !default;
$card-img-overlay-padding:                                          $spacer !default;

$carousel-control-color:                                            var(--color-white-000) !default;
$carousel-control-width:                                            15% !default;
$carousel-control-opacity:                                          .5 !default;
$carousel-control-hover-opacity:                                    .9 !default;
$carousel-control-transition:                                       opacity .15s ease !default;

$carousel-indicator-width:                                          30px !default;
$carousel-indicator-height:                                         3px !default;
$carousel-indicator-hit-area-height:                                10px !default;
$carousel-indicator-spacer:                                         3px !default;
$carousel-indicator-opacity:                                        .5 !default;
$carousel-indicator-active-bg:                                      var(--color-white-000) !default;
$carousel-indicator-active-opacity:                                 1 !default;
$carousel-indicator-transition:                                     opacity .6s ease !default;

$carousel-caption-width:                                            70% !default;
$carousel-caption-color:                                            var(--color-white-000) !default;
$carousel-caption-padding-y:                                        1.25rem !default;
$carousel-caption-spacer:                                           1.25rem !default;

$carousel-control-icon-width:                                       2rem !default;

$carousel-control-prev-icon-bg:                                     url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$carousel-control-color}'><path d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/></svg>") !default;
$carousel-control-next-icon-bg:                                     url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$carousel-control-color}'><path d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/></svg>") !default;

$carousel-transition-duration:                                      .6s !default;
$carousel-transition:                                               transform $carousel-transition-duration ease-in-out !default;

$carousel-dark-indicator-active-bg:                                 var(--color-black-000) !default;
$carousel-dark-caption-color:                                       var(--color-black-000) !default;
$carousel-dark-control-icon-filter:                                 invert(1) grayscale(100) !default;

$dropdown-min-width:                                                10rem !default;
$dropdown-padding-x:                                                0 !default;
$dropdown-padding-y:                                                .5rem !default;
$dropdown-spacer:                                                   .125rem !default;
$dropdown-font-size:                                                $font-size-base !default;
$dropdown-color:                                                    var(--#{$prefix}body-color) !default;
$dropdown-bg:                                                       var(--#{$prefix}body-bg) !default;
$dropdown-border-color:                                             var(--#{$prefix}border-color-translucent) !default;
$dropdown-border-radius:                                            var(--#{$prefix}border-radius) !default;
$dropdown-border-width:                                             var(--#{$prefix}border-width) !default;
$dropdown-inner-border-radius:                                      calc(#{$dropdown-border-radius} - #{$dropdown-border-width}) !default;
$dropdown-divider-bg:                                               $dropdown-border-color !default;
$dropdown-divider-margin-y:                                         $spacer * .5 !default;
$dropdown-box-shadow:                                               var(--#{$prefix}box-shadow) !default;

$dropdown-link-color:                                               var(--#{$prefix}body-color) !default;
$dropdown-link-hover-color:                                         $dropdown-link-color !default;
$dropdown-link-hover-bg:                                            var(--#{$prefix}tertiary-bg) !default;

$dropdown-link-active-color:                                        $component-active-color !default;
$dropdown-link-active-bg:                                           $component-active-bg !default;

$dropdown-link-disabled-color:                                      var(--#{$prefix}tertiary-color) !default;

$dropdown-item-padding-y:                                           $spacer * .25 !default;
$dropdown-item-padding-x:                                           $spacer !default;

$dropdown-header-color:                                             var(--color-gray-600) !default;
$dropdown-header-padding-x:                                         $dropdown-item-padding-x !default;
$dropdown-header-padding-y:                                         $dropdown-padding-y !default;

$caret-width:                                                       .3em !default;
$caret-vertical-align:                                              $caret-width * .85 !default;
$caret-spacing:                                                     $caret-width * .85 !default;


$list-group-color:                                                  var(--#{$prefix}body-color) !default;
$list-group-bg:                                                     var(--#{$prefix}body-bg) !default;
$list-group-border-color:                                           var(--#{$prefix}border-color) !default;
$list-group-border-width:                                           var(--#{$prefix}border-width) !default;
$list-group-border-radius:                                          var(--#{$prefix}border-radius) !default;

$list-group-item-padding-y:                                         $spacer * .5 !default;
$list-group-item-padding-x:                                         $spacer !default;

$list-group-hover-bg:                                               var(--#{$prefix}tertiary-bg) !default;
$list-group-active-color:                                           $component-active-color !default;
$list-group-active-bg:                                              $component-active-bg !default;
$list-group-active-border-color:                                    $list-group-active-bg !default;

$list-group-disabled-color:                                         var(--#{$prefix}secondary-color) !default;
$list-group-disabled-bg:                                            $list-group-bg !default;

$list-group-action-color:                                           var(--#{$prefix}secondary-color) !default;
$list-group-action-hover-color:                                     var(--#{$prefix}emphasis-color) !default;

$list-group-action-active-color:                                    var(--#{$prefix}body-color) !default;
$list-group-action-active-bg:                                       var(--#{$prefix}secondary-bg) !default;


$modal-inner-padding:                                               $spacer !default;

$modal-footer-margin-between:                                       .5rem !default;

$modal-dialog-margin:                                               .5rem !default;
$modal-dialog-margin-y-sm-up:                                       1.75rem !default;

$modal-title-line-height:                                           $line-height-base !default;

$modal-content-color:                                               null !default;
$modal-content-bg:                                                  var(--#{$prefix}body-bg) !default;
$modal-content-border-color:                                        var(--#{$prefix}border-color-translucent) !default;
$modal-content-border-width:                                        var(--#{$prefix}border-width) !default;
$modal-content-border-radius:                                       var(--#{$prefix}border-radius-lg) !default;
$modal-content-inner-border-radius:                                 subtract($modal-content-border-radius, $modal-content-border-width) !default;
$modal-content-box-shadow-xs:                                       var(--#{$prefix}box-shadow-sm) !default;
$modal-content-box-shadow-sm-up:                                    var(--#{$prefix}box-shadow) !default;

$modal-backdrop-bg:                                                 var(--color-black-000) !default;
$modal-backdrop-opacity:                                            .5 !default;

$modal-header-border-color:                                         var(--#{$prefix}border-color) !default;
$modal-header-border-width:                                         $modal-content-border-width !default;
$modal-header-padding-y:                                            $modal-inner-padding !default;
$modal-header-padding-x:                                            $modal-inner-padding !default;
$modal-header-padding:                                              $modal-header-padding-y $modal-header-padding-x !default;

$modal-footer-bg:                                                   null !default;
$modal-footer-border-color:                                         $modal-header-border-color !default;
$modal-footer-border-width:                                         $modal-header-border-width !default;

$modal-sm:                                                          300px !default;
$modal-md:                                                          500px !default;
$modal-lg:                                                          800px !default;
$modal-xl:                                                          1140px !default;

$modal-fade-transform:                                              translate(0, -50px) !default;
$modal-show-transform:                                              none !default;
$modal-transition:                                                  transform .3s ease-out !default;
$modal-scale-transform:                                             scale(1.02) !default;

$offcanvas-padding-y:                                               $modal-inner-padding !default;
$offcanvas-padding-x:                                               $modal-inner-padding !default;
$offcanvas-horizontal-width:                                        400px !default;
$offcanvas-vertical-height:                                         30vh !default;
$offcanvas-transition-duration:                                     .3s !default;
$offcanvas-border-color:                                            $modal-content-border-color !default;
$offcanvas-border-width:                                            $modal-content-border-width !default;
$offcanvas-title-line-height:                                       $modal-title-line-height !default;
$offcanvas-bg-color:                                                var(--#{$prefix}body-bg) !default;
$offcanvas-color:                                                   var(--#{$prefix}body-color) !default;
$offcanvas-box-shadow:                                              $modal-content-box-shadow-xs !default;
$offcanvas-backdrop-bg:                                             $modal-backdrop-bg !default;
$offcanvas-backdrop-opacity:                                        $modal-backdrop-opacity !default;

$pagination-padding-y:                                              .375rem !default;
$pagination-padding-x:                                              .75rem !default;
$pagination-padding-y-sm:                                           .25rem !default;
$pagination-padding-x-sm:                                           .5rem !default;
$pagination-padding-y-lg:                                           .75rem !default;
$pagination-padding-x-lg:                                           1.5rem !default;

$pagination-font-size:                                              $font-size-base !default;

$pagination-color:                                                  var(--#{$prefix}link-color) !default;
$pagination-bg:                                                     var(--#{$prefix}body-bg) !default;
$pagination-border-radius:                                          var(--#{$prefix}border-radius) !default;
$pagination-border-width:                                           var(--#{$prefix}border-width) !default;
$pagination-margin-start:                                           calc(#{$pagination-border-width} * -1) !default;
$pagination-border-color:                                           var(--#{$prefix}border-color) !default;

$pagination-focus-color:                                            var(--#{$prefix}link-hover-color) !default;
$pagination-focus-bg:                                               var(--#{$prefix}secondary-bg) !default;
$pagination-focus-box-shadow:                                       $focus-ring-box-shadow !default;
$pagination-focus-outline:                                          0 !default;

$pagination-hover-color:                                            var(--#{$prefix}link-hover-color) !default;
$pagination-hover-bg:                                               var(--#{$prefix}tertiary-bg) !default;
$pagination-hover-border-color:                                     var(--#{$prefix}border-color) !default;

$pagination-active-color:                                           $component-active-color !default;
$pagination-active-bg:                                              $component-active-bg !default;
$pagination-active-border-color:                                    $component-active-bg !default;

$pagination-disabled-color:                                         var(--#{$prefix}secondary-color) !default;
$pagination-disabled-bg:                                            var(--#{$prefix}secondary-bg) !default;
$pagination-disabled-border-color:                                  var(--#{$prefix}border-color) !default;

$pagination-transition:                                             color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !default;

$pagination-border-radius-sm:                                       var(--#{$prefix}border-radius-sm) !default;
$pagination-border-radius-lg:                                       var(--#{$prefix}border-radius-lg) !default;

$placeholder-opacity-max:                                           .5 !default;
$placeholder-opacity-min:                                           .2 !default;

$popover-font-size:                                                 $font-size-sm !default;
$popover-bg:                                                        var(--#{$prefix}body-bg) !default;
$popover-max-width:                                                 276px !default;
$popover-border-width:                                              var(--#{$prefix}border-width) !default;
$popover-border-color:                                              var(--#{$prefix}border-color-translucent) !default;
$popover-border-radius:                                             var(--#{$prefix}border-radius-lg) !default;
$popover-inner-border-radius:                                       calc(#{$popover-border-radius} - #{$popover-border-width}) !default;
$popover-box-shadow:                                                var(--#{$prefix}box-shadow) !default;

$popover-header-font-size:                                          $font-size-base !default;
$popover-header-bg:                                                 var(--#{$prefix}secondary-bg) !default;
$popover-header-color:                                              null; // $headings-color !default;
$popover-header-padding-y:                                          .5rem !default;
$popover-header-padding-x:                                          $spacer !default;

$popover-body-color:                                                var(--#{$prefix}body-color) !default;
$popover-body-padding-y:                                            $spacer !default;
$popover-body-padding-x:                                            $spacer !default;

$popover-arrow-width:                                               1rem !default;
$popover-arrow-height:                                              .5rem !default;


$progress-height:                                                   1rem !default;
$progress-font-size:                                                $font-size-base * .75 !default;
$progress-bg:                                                       var(--#{$prefix}secondary-bg) !default;
$progress-border-radius:                                            var(--#{$prefix}border-radius) !default;
$progress-box-shadow:                                               var(--#{$prefix}box-shadow-inset) !default;
$progress-bar-color:                                                var(--color-white-000) !default;
$progress-bar-bg:                                                   var(--color-primary-000) !default;
$progress-bar-animation-timing:                                     1s linear infinite !default;
$progress-bar-transition:                                           width .6s ease !default;


$spinner-width:                                                     3.2rem !default;
$spinner-height:                                                    $spinner-width !default;
$spinner-vertical-align:                                            -.125em !default;
$spinner-border-width:                                              .25em !default;
$spinner-animation-speed:                                           .75s !default;

$spinner-width-sm:                                                  1.6rem !default;
$spinner-height-sm:                                                 $spinner-width-sm !default;
$spinner-border-width-sm:                                           .2em !default;

$toast-max-width:                                                   350px !default;
$toast-padding-x:                                                   .75rem !default;
$toast-padding-y:                                                   .5rem !default;
$toast-font-size:                                                   .875rem !default;
$toast-color:                                                       null !default;
$toast-background-color:                                            rgba(var(--#{$prefix}body-bg-rgb), .85) !default;
$toast-border-width:                                                var(--#{$prefix}border-width) !default;
$toast-border-color:                                                var(--#{$prefix}border-color-translucent) !default;
$toast-border-radius:                                               var(--#{$prefix}border-radius) !default;
$toast-box-shadow:                                                  var(--#{$prefix}box-shadow) !default;
$toast-spacing:                                                     $container-padding-x !default;

$toast-header-color:                                                var(--#{$prefix}secondary-color) !default;
$toast-header-background-color:                                     rgba(var(--#{$prefix}body-bg-rgb), .85) !default;
$toast-header-border-color:                                         $toast-border-color !default;

$tooltip-font-size:                                                 $font-size-sm !default;
$tooltip-max-width:                                                 200px !default;
$tooltip-color:                                                     var(--#{$prefix}body-bg) !default;
$tooltip-bg:                                                        var(--#{$prefix}emphasis-color) !default;
$tooltip-border-radius:                                             var(--#{$prefix}border-radius) !default;
$tooltip-opacity:                                                   .9 !default;
$tooltip-padding-y:                                                 $spacer * .25 !default;
$tooltip-padding-x:                                                 $spacer * .5 !default;
$tooltip-margin:                                                    null !default; // TODO: remove this in v6

$tooltip-arrow-width:                                               .8rem !default;
$tooltip-arrow-height:                                              .4rem !default;
